<template>
  <div>
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active>
        <span>Info</span>
      </CBreadcrumbItem>
    </teleport>

    <CCard>
      <CCardBody>
        <div
          v-if="loading"
          class="d-flex align-items-center justify-content-between p-4"
        >
          <CSpinner color="primary" />
        </div>
        <CContainer v-else class="ms-0">
          <CRow>
            <div v-if="user.u_status !== 'active'" class="alert alert-danger text-center" role="alert">
              <strong>
                Account deleted
              </strong>
            </div>
            <CCol xl="2" lg="3">
              <Avatar
                :src="user.photo"
                :display-name="[user.first_name, user.last_name]"
                class="user_photo" />
            </CCol>
            <CCol xl="10" lg="9">
              <h1 class="fw-bold">
                {{ user.first_name }} {{ user.last_name }}
              </h1>
              <CRow>
                <CCol lg="5">
                  <div
                    class="d-flex align-items-center justify-content-between mb-3"
                  >
                    <span class="col-6">Title</span>
                    <span class="fw-medium col-6 ellipsis">{{
                      user.job_title
                    }}</span>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-3"
                  >
                    <span class="c0l-6">Company</span>
                    <router-link
                      v-if="user.company"
                      class="fw-medium col-6"
                      :to="{
                        name: 'CompanyInfo',
                        params: { id: user.company.id },
                      }"
                    >
                      {{ user.company.name }}
                    </router-link>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-3"
                  >
                    <span class="col-6">Website</span>
                    <a
                      v-if="user.company && user.company.website"
                      class="fw-medium col-6 pointer"
                      target="_blank"
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                        white-space: nowrap;
                      "
                      @click="goWebsite(user.company.website)"
                    >
                      {{ user.company.website }}
                    </a>
                    <span v-else class="col-6">N/A</span>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-3"
                  >
                    <span class="col-6 ellipsis">Email</span>
                    <a
                      :href="'mailto:' + user.email"
                      class="fw-medium col-6 ellipsis"
                      target="_blank"
                    >
                      {{ user.email }}
                    </a>
                  </div>
                </CCol>
                <CCol class="border-end flex-grow-0 d-none d-lg-block"></CCol>
                <CCol lg="5">
                  <div
                    class="d-flex align-items-center justify-content-between mb-3"
                  >
                    <span class="col-6 ellipsis">Office / Region</span>
                    <span class="fw-medium col-6 ellipsis">{{
                      user.office?.name
                    }}</span>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-3"
                  >
                    <span class="col-6 ellipsis">Business Phone</span>
                    <span class="fw-medium col-6 ellipsis">{{
                      user.business_phone ? user.business_phone : 'N/A'
                    }}</span>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-3"
                  >
                    <span class="col-6 ellipsis">Cell Phone</span>
                    <a
                      :href="'tel:' + user.cell_phone"
                      class="fw-medium col-6 ellipsis"
                      target="_blank"
                      >{{ user.cell_phone ? user.cell_phone : 'N/A' }}</a
                    >
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-3"
                  >
                    <span class="col-6 ellipsis">Fax</span>
                    <span class="fw-medium col-6 ellipsis">{{
                      user.fax ? user.fax : 'N/A'
                    }}</span>
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue";

export default {
  name: 'UserDetails',
  components: { Avatar },
  inject: ['mountedComponent'],
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: () => ({}),
    },
  },
  methods: {
    goWebsite(url) {
      window.open(url.indexOf('http') !== 0 ? 'https://' + url : url, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.user_photo {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  object-fit: cover;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
</style>
